
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.main-banner {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: flex-start;
    height: auto;
    min-height: calc(100vh - 52px);

    .form {
        z-index: 5;
        width: 100%;
        padding: 30px 24px;
        max-width: 700px;
        border-radius: 10px;
        min-height: 500px;
        background-color: white;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
        position: relative;
        border-radius: 15px;

        > button {
            color: #9b9b9b;
        }

        .steps-inner {
            flex-grow: 1;
            padding-bottom: 20px;
        }
        h1 {
            &.step-title {
                font-size: 2.6rem;

                margin: 0;
                @include lg {
                    font-size: 2.8rem !important;
                    line-height: 1.3;
                }
            }
        }
        .step-title {
            text-align: left;
            font-size: 24px !important;
            font-weight: 700 !important;
            margin-bottom: 10px;

            color: black !important;
        }
        .label-as-step-title {
            text-align: center;
            font-size: 20px !important;
            font-weight: 700 !important;
            margin-top: -20px;
            margin-bottom: 25px !important;
            color: black !important;
        }

        .fields-wrapper {
            margin-top: 1.5rem;
        }
        .form-button {
            max-width: unset !important;
            margin: 0 auto !important;
            margin-top: 5px !important;
            border-radius: 3px !important;
            background: $businesscopymachines-mainBanner-labelSelcted-color;
            transition: all 0.2s ease-in-out;
            border-radius: 5px !important;
            color: black;

            @apply shadow-lg;

            &:hover {
                @apply shadow;
            }
        }
        .form-field {
            max-width: unset;
            margin: 0 auto;
            margin-bottom: 0.5rem;
        }
        .radio-field {
            display: flex;

            flex-direction: column;
            flex-wrap: wrap;

            margin-top: 10px;
            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;

                input {
                    &:checked + label {
                        font-weight: 500;

                        background: $businesscopymachines-mainBanner-labelSelcted-color;
                    }
                }
                label {
                    border-radius: 8px;

                    border: 1px solid black;
                    background-color: $businesscopymachines-mainBanner-labelUnSelcted-color;
                    &:hover {
                        background: $businesscopymachines-mainBanner-labelSelcted-color;
                    }

                    > span {
                        margin-bottom: 7.5px;
                        width: 100%;
                        font-size: 14px;

                        @include md {
                            font-size: 16px;
                        }
                    }

                    color: black;
                    font-size: 14px;
                    display: flex;
                    align-items: center;

                    flex-direction: row;
                    gap: 10px;
                    @include md {
                        min-height: 64px;
                    }
                    svg {
                        margin-bottom: 10px;
                        width: 30px;
                        height: auto;
                    }
                    img {
                        display: none;
                    }
                }

                @include lg {
                    margin: 0 auto;
                    margin-bottom: 15px;
                    width: 80%;
                }
            }
            @include lg {
                margin: 0 -10px;
            }
        }

        .field-note {
            margin-left: 7px;
        }
        .field-error-message {
            left: 7px;
        }
        .field {
            &:not(.radio-field) {
                border-radius: 5px;
                background-color: white;
            }
        }
        .tcpa {
            margin-bottom: 10px;
        }
        @include lg {
            min-width: 300px;
            width: 60%;
            padding: 40px 40px;
            margin-bottom: 200px;
            margin-top: 50px;
        }
        .step {
            &:first-child {
                .form-field {
                    > label {
                        text-align: center;
                        margin-bottom: 1rem;
                        font-size: 14px;
                        @include lg {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
    .image {
        z-index: 2;
        & > div {
            position: absolute !important;
            left: 0;
            bottom: 0;
            height: 300px;
            width: 100vw;
            max-width: unset !important;
            left: 50% !important;
            transform: translateX(-50%) !important;

            @include lg {
                height: 100%;
                top: 0 !important;
                left: 0%;
            }
        }

        img {
            min-height: unset !important;
            min-width: unset !important;
            width: auto !important;
            height: 100% !important;
            max-width: unset !important;
            margin-left: 0 !important;
            margin-right: auto !important;
        }
    }

    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        background-color: $businesscopymachines-mainBanner-img-color;
        background-image: url("/assets/images/mobile-bg (2)Opt.jpg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right bottom;
        z-index: -10;
        @include lg {
            background-image: url("/assets/images/main-imgOpt.jpg");
            background-color: $businesscopymachines-mainBanner-img-color;
            background-position: right bottom;
            background-size: cover;
        }
    }
}
